<template>
  <b-select size="sm" v-model="bot" :options="bots"></b-select>
</template>

<script>
import _ from 'lodash';
import skypeBotsServices from "../../services/SkypeBots/SkypeBotsServices";

export default {
  data() {
    return {
      botId: 0
    }
  },
  mounted() {
    this.getSkypeBots();
  },
  computed: {
    bot: {
      get() {
        return this.$store.state.selectedSkypeBot || this.bots[0].value;
      },
      set(skypeBot) {
        this.$store.commit('updateSelectedSkypeBot', skypeBot);
        this.$emit('botChanged', skypeBot);
      },
    },
    bots() {
      return this.$store.state.skypeBots;
    }
  },
  methods: {
    async getSkypeBots() {
      const resp = await skypeBotsServices.getList();
      let bots = resp.data;
      bots = _.map(bots, (b) => {
        return {value: {botId: b.botId, botName: b.botName}, text: b.botName};
      });
      bots = _.orderBy(bots, ['text']);
      
      this.$store.commit('updateSkypeBots', bots);

      if (bots.length > 0 && this.botId === null) {
        this.botId = this.bots[0].value;
      }
    },
    changeBot() {
      this.$store.commit('updateSelectedSkypeBot', this.botId);
    }
  }
}
</script>

<style scoped>

</style>