<template>
  <div>
    <div class="row mb-3">
      <div class="col">
        <h1>Blacklist Management</h1>
      </div>
    </div>
    <b-row class="mb-3">
      <b-col cols="2">Bot</b-col>
      <b-col cols="2">
        <skype-bot-selector @botChanged="(bot) => {searchParams.botId = bot.botId;} "/>
      </b-col>
      <b-col cols="2"></b-col>
      <b-col cols="4"></b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col cols="2"> Affiliate Code</b-col>
      <b-col cols="2">
        <b-input v-model="searchParams.affiliateCode" size="sm"/>
      </b-col>
      <b-col cols="2"> Created Date</b-col>
      <b-col cols="6">
        <div class="form-inline">
          <small class="text-muted mr-1">from</small>
          <b-form-datepicker
              v-model="searchParams.createdAtFrom"
              :min="minDate"
              :max="searchParams.createdAtTo"
              size="sm"
              :date-format-options="dateFormat"
          />
          <small class="text-muted mx-1">to</small>
          <b-form-datepicker
              v-model="searchParams.createdAtTo"
              :min="searchParams.createdAtFrom"
              :max="maxDate"
              size="sm"
              :date-format-options="dateFormat"
          />
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col cols="2">Channel Group Name</b-col>
      <b-col cols="2">
        <b-input v-model="searchParams.channelGroupName" size="sm"/>
      </b-col>
      <b-col cols="2"> Updated Date</b-col>
      <b-col cols="6">
        <div class="form-inline">
          <small class="text-muted mr-1">from</small>
          <b-form-datepicker
              v-model="searchParams.updatedAtFrom"
              :min="minDate"
              :max="searchParams.updatedAtTo"
              placeholder="all"
              size="sm"
              :date-format-options="dateFormat"
          />
          <small class="text-muted mx-1">to</small>
          <b-form-datepicker
              v-model="searchParams.updatedAtTo"
              :min="searchParams.updatedAtFrom"
              :max="maxDate"
              placeholder="all"
              size="sm"
              :date-format-options="dateFormat"
          />
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col cols="2">Channel User Name</b-col>
      <b-col cols="2">
        <b-input v-model="searchParams.channelUsername" size="sm"/>
      </b-col>
      <b-col cols="2">Status</b-col>
      <b-col cols="2">
        <b-form-select
            v-model="searchParams.status"
            size="sm"
            :options="statusOptions"
        ></b-form-select>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col>
        <b-button
            class="ml-2 float-right"
            variant="primary"
            size="sm"
            @click="searchBlockList"
        >
          Search
        </b-button>
        <b-button
            class="ml-2 float-right"
            variant="outline-primary"
            size="sm"
            @click="exportBlockList"
        >
          Export
        </b-button>
        <b-button
            class="ml-2 float-right"
            variant="outline-primary"
            size="sm"
            @click="showCreateBlockList"
        >
          Blocklist
        </b-button>
      </b-col>
    </b-row>
    <section v-if="items.length">
      <b-row class="mb-3">
        <b-col>
          <b-button
              class="ml-2 float-right"
              variant="outline-primary"
              size="sm"
              @click="unblacklistSelected"
          >
            Unblocklist Selected
          </b-button>
          <b-button
              class="ml-2 float-right"
              variant="outline-primary"
              size="sm"
              @click="unblacklistAll"
          >
            Unblocklist All
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              size="sm"
              align="right"
              limit="10"
              v-if="rows > 0"
          ></b-pagination>
          <b-table
              bordered
              small
              selectable
              select-mode="multi"
              :responsive="true"
              :items="items"
              :fields="fields"
              :per-page="perPage"
              :current-page="currentPage"
              :busy="isBusy"
              :primary-key="id"
              @row-selected="rowSelected"
          >
            <template v-slot:table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template v-slot:cell(channelUsername)="data">
              <span v-if="data.value" :title="data.item['channelUserId']">{{
                  data.value
                }}</span>
              <small class="text-muted" v-else
              >ID: {{ data.item['channelUserId'] }}</small
              >
            </template>
            <template v-slot:cell(channelGroupName)="data">
              <span :title="data.item['channelGroupId']">{{ data.value }}</span>
            </template>
            <template v-slot:cell(createdAt)="data">
              {{ data.value | datetimelong }}
            </template>
            <template v-slot:cell(createdBy)="data">
              {{ data.value.split('____')[0] }}
            </template>
            <template v-slot:cell(updatedAt)="data">
              {{
                data.item['createdAt'] === data.item['updatedAt']
                    ? ''
                    : data.value | datetimelong
              }}
            </template>
            <template v-slot:cell(updatedBy)="data">
              {{
                data.item['createdAt'] === data.item['updatedAt']
                    ? ''
                    : data.value.split('____')[0]
              }}
            </template>
            <template v-slot:cell(isBlacklisted)="data"
            >{{ data.value ? 'Blacklist' : 'Unblacklist' }}
            </template>
            <template v-slot:cell(id)="data">
              <b-button
                  @click.prevent="showDetails(data.item)"
                  size="sm"
                  variant="outline-info"
              >
                Details
              </b-button>
            </template>
            <template v-slot:row-details="data">
              <message-details :message="JSON.stringify(data.item)"/>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </section>

    <b-modal
        size="xl"
        title="New blocklist"
        scrollable
        button-size="sm"
        :ok-title="submitButtonText"
        @ok.prevent="submitBlackList"
        v-model="modalCreateBlacklist"
    >
      <b-form ref="formCreateBlacklist">
        <b-row class="mb-2">
          <b-col>
            <span>User ID</span>
          </b-col>
          <b-col>
            <span>Username</span>
          </b-col>
          <b-col>
            <span>Group Name</span>
          </b-col>
          <b-col>
            <span>Affiliate Code</span>
          </b-col>
          <b-col>
            <span>Blacklist Type</span>
          </b-col>
          <b-col cols="3">
            <span>Remark</span>
          </b-col>
          <b-col cols="1"></b-col>
        </b-row>
        <b-row
            class="mb-2"
            v-for="(blacklist, index) in newBlackList"
            :key="index"
        >
          <b-col>
            <b-form-input
                v-model="blacklist.channelUserId"
                maxLength="200"
                size="sm"
                @change="resetSyncedData(index)"
            />
          </b-col>
          <b-col>
            <div
                v-if="
                blacklist.isSynced &&
                blacklist.channelUserId &&
                !Boolean(blacklist.channelSyncedUsername)
              "
            >
              <b-form-input
                  v-model="blacklist.channelUsername"
                  size="sm"
                  maxLength="200"
              />
            </div>
            <div v-else>{{ blacklist.channelSyncedUsername }}</div>
          </b-col>
          <b-col>
            {{ blacklist.channelGroupName }}
          </b-col>
          <b-col>
            {{ blacklist.affiliateCode }}
          </b-col>
          <b-col>
            <b-form-select
                v-model="blacklist.blacklistType"
                :options="blacklistTypes"
                :disabled="!Boolean(blacklist.channelUserId)"
                size="sm"
            />
          </b-col>
          <b-col cols="3">
            <b-form-input
                v-model="blacklist.remark"
                :disabled="!Boolean(blacklist.channelUserId)"
                :required="Boolean(blacklist.channelUserId)"
                size="sm"
            />
          </b-col>
          <b-col cols="1">
            <b-icon-check-circle v-if="blacklist.isSynced" variant="success"/>
          </b-col>
        </b-row>
      </b-form>
      <!--      <b-button-->
      <!--        v-if="effectiveNewBlacklist.length"-->
      <!--        class="float-right"-->
      <!--        variant="outline-primary"-->
      <!--        size="sm"-->
      <!--        @click="syncUserId"-->
      <!--      >-->
      <!--        Sync User ID-->
      <!--      </b-button>-->
    </b-modal>

    <b-modal size="lg" v-model="modalShowDetails" hide-footer centered>
      <b-row class="mb-2">
        <b-col>Record ID</b-col>
        <b-col>
          <b-form-input size="sm" disabled :value="selectedItem.id"/>
        </b-col>
        <b-col></b-col>
        <b-col></b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col>Channel</b-col>
        <b-col>
          <b-form-input size="sm" disabled value="Skype"/>
        </b-col>
        <b-col></b-col>
        <b-col></b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col>Affiliate Code</b-col>
        <b-col>
          <b-form-input
              size="sm"
              disabled
              :value="selectedItem.affiliateCode"
          />
        </b-col>
        <b-col></b-col>
        <b-col></b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col>Channel Group Name</b-col>
        <b-col>
          <b-form-input
              size="sm"
              disabled
              :value="selectedItem.channelGroupName"
          />
        </b-col>
        <b-col>Channel Group ID</b-col>
        <b-col>
          <b-form-input
              size="sm"
              disabled
              :value="selectedItem.channelGroupId"
          />
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col>Channel User Name</b-col>
        <b-col>
          <b-form-input
              size="sm"
              disabled
              :value="selectedItem.channelUsername"
          />
        </b-col>
        <b-col>Channel User ID</b-col>
        <b-col>
          <b-form-input
              size="sm"
              disabled
              :value="selectedItem.channelUserId"
          />
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col>Status</b-col>
        <b-col>
          <b-form-input
              size="sm"
              disabled
              :value="selectedItem.isBlacklisted ? 'Blacklist' : 'Unblacklist'"
          />
        </b-col>
        <b-col></b-col>
        <b-col></b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col>Blacklist Type</b-col>
        <b-col>
          <b-form-input
              size="sm"
              disabled
              :value="selectedItem.blacklistType"
          />
        </b-col>
        <b-col></b-col>
        <b-col></b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col cols="3">Remark</b-col>
        <b-col>
          <b-form-textarea size="sm" disabled :value="selectedItem.remark"/>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import SkypeBotSelector from '../../components/SkypeBots/SkypeBotSelector.vue';
import httpclient from '@/mixins/HttpHandler';
import MessageDetails from '@/components/ChatHistories/MessageDetails.vue';
import _ from 'lodash';

export default {
  name: 'AntiSpam',
  mixins: [httpclient],
  components: {MessageDetails, SkypeBotSelector},
  data() {
    return {
      dateFormat: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      },
      statusOptions: [
        {value: null, text: 'All'},
        {value: true, text: 'Blacklist'},
        {value: false, text: 'Unblacklist'},
      ],
      createdDate: {},
      updatedDate: {},
      searchParams: {
        botId: null,
        affiliateCode: '',
        channelGroupName: '',
        ChannelUsername: '',
        createdAtFrom: dayjs(dayjs().startOf('day').toDate()).format(
            'YYYY-MM-DD'
        ),
        createdAtTo: dayjs(
            dayjs().add(1, 'days').startOf('day').toDate()
        ).format('YYYY-MM-DD'),
        updatedAtFrom: null,
        updatedAtTo: null,
        status: null,
      },

      modalShowDetails: false,
      selectedItem: {id: null},

      modalCreateBlacklist: false,
      newBlackList: Array.from({length: 10}, () => {
        return _defaultAntiSpam();
      }),
      blacklistTypes: [
        {value: 'Bad intention', text: 'Bad intention'},
        {value: 'Others', text: 'Others'},
      ],

      fields: [
        {
          key: 'affiliateCode',
          label: 'Aff Code',
          sortable: true,
        },
        {
          key: 'channelGroupName',
          label: 'Channel Group Name',
          sortable: true,
        },
        {
          key: 'channelUsername',
          label: 'Channel Username',
          sortable: true,
        },
        {
          key: 'createdAt',
          label: 'Created Date',
          sortable: true,
        },
        {
          key: 'createdBy',
          label: 'Created By',
          sortable: true,
        },
        {
          key: 'updatedAt',
          label: 'Updated Date',
          sortable: true,
        },
        {
          key: 'updatedBy',
          label: 'Updated By',
          sortable: true,
        },
        {
          key: 'isBlacklisted',
          label: 'Status',
          sortable: true,
        },
        {
          key: 'blackListType',
          label: 'Blacklist Type',
          sortable: true,
        },
        {
          key: 'id',
          label: 'Action',
          sortable: false,
        },
      ],
      items: [],
      perPage: 10,
      currentPage: 1,
      selectedRows: [],
    };
  },
  mounted() {
    this.searchParams.botId = this.$store.state.selectedSkypeBot.botId;
  },
  computed: {
    minDate() {
      return dayjs().add(-1, 'years').set('date', 1).startOf('day').toDate();
    },
    maxDate() {
      return dayjs().add(7, 'days').toDate();
    },
    rows() {
      return this.items.length;
    },
    notSyncedNewBlacklist() {
      return _.filter(this.newBlackList, (i) => {
        return Boolean(i.channelUserId) && !i.isSynced;
      });
    },
    syncedNewBlacklist() {
      return _.filter(this.newBlackList, (i) => {
        return Boolean(i.channelUserId) && i.isSynced;
      });
    },
    canSubmitBlackList() {
      return (
          this.syncedNewBlacklist.length > 0 &&
          this.notSyncedNewBlacklist.length === 0
      );
    },
    submitButtonText() {
      return this.canSubmitBlackList ? 'Submit' : 'Sync Data';
    },
  },
  methods: {
    showDetails(data) {
      this.selectedItem = {...data};
      this.modalShowDetails = true;
    },
    showCreateBlockList() {
      this.newBlackList = Array.from({length: 10}, () => {
        return _defaultAntiSpam();
      });
      this.modalCreateBlacklist = true;
    },
    rowSelected(selectedRows) {
      this.selectedRows = selectedRows;
    },
    resetSyncedData(index) {
      this.newBlackList = _.map(this.newBlackList, (current, i) => {
        if (i === index) {
          return {
            ..._defaultAntiSpam(),
            ...{
              channelUserId: current.channelUserId,
              blocklistType: current.blacklistType,
              remark: current.remark,
            },
          };
        }
        return current;
      });
    },
    async submitBlackList() {
      if (this.canSubmitBlackList) {
        await this.addBlackList();
      } else {
        await this.syncUserId();
      }
    },
    async syncUserId() {
      const botId = this.searchParams.botId;
      const list = _.map(this.notSyncedNewBlacklist, (i) => {
        return {channelUserId: i.channelUserId};
      });
      const response = await this.httpclient.post(
          `api/AntiSpams/bots/${botId}/sync-user-ids`,
          list
      );
      let syncedData = response.data;
      this.newBlackList = _.map(this.newBlackList, (b) => {
        if (!b.channelUserId) return b;

        b.isSynced = true;
        let item = _.find(syncedData, {channelUserId: b.channelUserId});
        if (item) {
          return {
            ...b,
            ...{
              channelUsername: item.channelUsername,
              channelSyncedUsername: item.channelUsername,
              channelGroupId: item.channelGroupId,
              channelGroupName: item.channelGroupName,
              affiliateCode: item.affiliateCode,
            },
          };
        }

        return b;
      });
      await this.$bvModal.msgBoxOk(
          'User data synced',
          this._modalDefaultOptions()
      );
      return response;
    },
    async addBlackList() {
      const mainForm = this.$refs['formCreateBlacklist'];

      if (!mainForm.reportValidity()) {
        return;
      }

      const syncedList = [...this.syncedNewBlacklist];
      const userIds = _.map(syncedList, 'channelUserId').join(', ');
      let confirm = await this.$bvModal.msgBoxConfirm(
          `Proceed to blocklist these users? (${userIds})`,
          this._modalDefaultOptions({
            okTitle: 'Proceed',
          })
      );

      if (!confirm) {
        return;
      }

      const botId = this.searchParams.botId;
      try {
        const response = await this.httpclient.post(
            `api/AntiSpams/bots/${botId}`,
            syncedList
        );
        await this.$bvModal.msgBoxOk(
            'Users had been blacklisted.',
            this._modalDefaultOptions()
        );
        return response;
      } finally {
        this.modalCreateBlacklist = false;
      }
    },
    async searchBlockList() {
      const searchParams = {...this.searchParams};
      const response = await this.httpclient.get('api/AntiSpams', {
        params: searchParams,
      });
      this.items = response.data;
    },
    async exportBlockList() {
      const searchParams = {...this.searchParams};
      this._downloadFile('api/AntiSpams/export', searchParams, () => {
        this.isBusy = false;
      });
    },
    async unblacklistAll() {
      const items = _.filter(this.items, {isBlacklisted: true});
      await this.unblacklist(items);
    },
    async unblacklistSelected() {
      const items = _.filter(this.selectedRows, {isBlacklisted: true});
      await this.unblacklist(items);
    },
    async unblacklist(items) {
      if (items.length === 0) {
        await this.$bvModal.msgBoxOk(
            'No user selected or the selected users are already unblacklisted.',
            this._modalDefaultOptions()
        );
        return;
      }

      let confirm = await this.$bvModal.msgBoxConfirm(
          `Proceed to unblocklist these ${items.length} users?`,
          this._modalDefaultOptions({
            okTitle: 'Proceed',
          })
      );

      if (!confirm) {
        return;
      }

      const botId = this.searchParams.botId;
      var list = _.map(items, (i) => {
        return {id: i.id};
      });
      const response = await this.httpclient.put(
          `api/AntiSpams/bots/${botId}`,
          list
      );
      await this.$bvModal.msgBoxOk(
          'Users had been unblacklisted.',
          this._modalDefaultOptions()
      );
      await this.searchBlockList();

      return response;
    },
    defaultRanges() {
      const today = dayjs().startOf('day').toDate();
      const tomorrow = dayjs().add(1, 'days').startOf('day').toDate();
      const yesterday = dayjs().add(-1, 'days').startOf('day').toDate();
      const thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
      const thisMonday = dayjs().startOf('isoWeek');
      const prevMonday = dayjs().add(-7, 'days').startOf('isoWeek');
      return {
        Today: [today, tomorrow],
        Yesterday: [yesterday, today],
        'Previous Week': [prevMonday, thisMonday],
        'This Week': [thisMonday, tomorrow],
        'This Month': [thisMonthStart, tomorrow],
      };
    },
  },
};

const _defaultAntiSpam = () => {
  return {
    channelUserId: null,
    channelUsername: null,
    channelGroupId: null,
    channelGroupName: null,
    affiliateCode: null,
    blacklistType: 'Bad intention',
    remark: null,
    isSynced: false,
    channelSyncedUsername: null,
  };
};
</script>

<style scoped></style>
