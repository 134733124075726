<template>
  <div>
    <JsonViewer v-model="messageJson" :expand-depth="5" :expanded="true" />
  </div>
</template>

<script>
import JsonViewer from '@/components/UI/JsonViewer';

export default {
  name: 'MessageDetails',
  props: {
    message: { type: String, default: '{}' },
  },
  components: { JsonViewer },
  computed: {
    messageJson() {
      try {
        return JSON.parse(this.message);
      } catch (ex) {
        return {};
      }
    },
  },
};
</script>

<style scoped></style>